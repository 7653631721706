import React from "react";
import {observer} from "mobx-react-lite";
import {AppBar, Box, Drawer, Stack} from "@mui/material";

import {PsnaAdminToolBar} from "./PsnaAdminToolBar";
import { NavLink, Outlet} from "react-router-dom";
import {usePermissions} from "./providers/PermissionContext";
export const Administration = observer(() =>{
    const drawerWidth = 240;
    const permissions = usePermissions();
    if (!permissions.canApproveRegistration){
        return <div>Access Denied</div>;
    }
    return (
        <>
            <AppBar  sx={{width: `calc(100% - ${drawerWidth}px)`,marginLeft: `${drawerWidth}px`}}>
                 <PsnaAdminToolBar/>
            </AppBar>

            <div  style={{width: `calc(100% - ${drawerWidth+100}px)`,marginLeft:`${drawerWidth+50}px`, marginRight:"50px", marginTop:'100px'}} >
                <Outlet></Outlet>

            </div>
            <Drawer   open={true} variant="persistent">
                <Box   bgcolor="primary.main" sx={{
                    width: `${drawerWidth}px`,
                    height: "100%",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: `${drawerWidth}px`,
                        boxSizing: 'border-box',
                    },
                }}>
                    <div style={{marginTop : "60px", marginLeft:"20px"}} >
                        <Stack>
                        <NavLink to=""    className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }>Registrations</NavLink>
                        <NavLink to="updatepolicyholders"   className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }>Upload Policy Holders</NavLink>
                        </Stack>
                    </div>
                </Box>
            </Drawer>
        </>


    );
});