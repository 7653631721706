import {makeAutoObservable, observable} from "mobx";

export class Portal {
    constructor() {
        makeAutoObservable(this)

    }
    // set for downloadUrl according to mbox guidelines

    @observable downloadCertificateUrl :string = ""
}
