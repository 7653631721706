import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@aws-amplify/ui-react/styles.css';
import reportWebVitals from './reportWebVitals';
import { Authenticator } from '@aws-amplify/ui-react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {PublicRegisterForm} from "./PublicRegisterForm";
import {RegisterForm} from "./RegisterForm";
import {NavigateToRegistrationTypeComponent} from "./NavigateToRegistrationTypeComponent";
import {Registration} from "./Registration";
import {NavigateComponent} from "./NavigateComponent";
import {Administration} from "./Administration";
import {Acknowledgement} from "./Acknowledgement";
import {NavigateToAdminRegistrationTypeComponent} from "./NavigateToAdminRegistrationTypeComponent";
import {AdminRegisterForm} from "./AdminRegisterForm";
import {ProviderPortal} from "./ProviderPortal";
import {AdminPublicRegisterForm} from "./AdminPublicRegisterForm";
import {StoreProvider} from "./providers/StoreProvider";
import {UpdatePolicyHolders} from "./UpdatePolicyHolders";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children:[
            {
                path: "",
                element:<NavigateComponent></NavigateComponent>
            },
            {
                path: "registration",
                element:<Registration />,
                children:[
                    {
                        path: "",
                        element:<NavigateToRegistrationTypeComponent></NavigateToRegistrationTypeComponent>,
                    }  ,
                    {
                        path: "public",
                        element:<PublicRegisterForm  ></PublicRegisterForm>
                    }  ,
                    {
                        path: "private",
                        element:<RegisterForm ></RegisterForm>
                    },
                    {
                        path: "acknowledgement",
                        element:<Acknowledgement ></Acknowledgement>
                    },
                    {
                        path: "portal",
                        element:<ProviderPortal></ProviderPortal>
                    }

                ]
            }  ,
            {
                path: "administration",
                element:<Administration ></Administration>,
                children:[
                    {
                        path: "",
                        element:<NavigateToAdminRegistrationTypeComponent ></NavigateToAdminRegistrationTypeComponent>,
                    } ,
                    {
                        path: ":userId",
                        element:<NavigateToAdminRegistrationTypeComponent></NavigateToAdminRegistrationTypeComponent>,
                    } ,
                    {
                        path: ":userId/private",
                        element:<AdminRegisterForm></AdminRegisterForm>
                    },
                    {
                        path: ":userId/public",
                        element:<AdminPublicRegisterForm ></AdminPublicRegisterForm>
                    },
                    {
                        path:"updatepolicyholders",
                        element:<UpdatePolicyHolders/>
                    }
                ]
            }  ,

        ]
    }
])


root.render(
  <React.StrictMode>
      <StoreProvider>
        <Authenticator.Provider>
          <RouterProvider router={router}/>
        </Authenticator.Provider>
      </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
