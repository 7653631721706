import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {Box} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {uploadData} from "@aws-amplify/storage";
import {useStore} from "./providers/StoreContext";
import {MuiFileInput} from "mui-file-input";

export const UpdatePolicyHolders =  observer(() => {
    const store = useStore();
    const {handleSubmit,control} = useForm( );
    const submitform = (formData: any) => {
        const asyncSubmitForm = async () => {
            store.UploadPolicyHoldersStore.Uploading =true;
            if (formData.policy_holders_file?.name) {
                store.registrationStore.toggleTinUploading();
                await uploadData({
                    path:()=>`uploadedpolicyholders/${formData.policy_holders_file?.name}`,
                    data: formData.policy_holders_file,
                }).result;
                store.UploadPolicyHoldersStore.Uploading = false;
                store.appStore.showSnackBar("Uploaded");
                store.registrationStore.toggleTinUploading();
                store.appStore.showSnackBar("Processing..");

            }
        }
        asyncSubmitForm().then();
    }

    return (
        <Box component="form" onSubmit={handleSubmit(submitform)}>
            <Controller   name="policy_holders_file"  control={control} render={({field,fieldState})=>(
                <MuiFileInput fullWidth={true} placeholder="Click to Select a File" sx={{background:"white"}} label="Policy Holders File"  {...field} helperText={fieldState.invalid ? "File is invalid" : ""}   error={fieldState.invalid}

                />)}/>
        <LoadingButton
            sx={{width: "100%", height: "100%"}}

            loading={store.UploadPolicyHoldersStore.Uploading}
            loadingIndicator=" Uploading…"
            variant="contained"
            type="submit"
        >
            <span>Upload</span>
        </LoadingButton>
        </Box>
    );
});