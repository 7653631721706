import {Navigate, NavigateFunction, useNavigate} from "react-router-dom";
import React from "react";
import {useMutation} from "@apollo/client";
import {GET_REGISTRATIONS, UPDATE_REGISTRATION_STATUS} from "./Queries";
import {useStore} from "./providers/StoreContext";

export const Acknowledgement = ()=> {
    const store = useStore();
    const navigate:NavigateFunction = useNavigate();
    const [updateStatus] = useMutation(UPDATE_REGISTRATION_STATUS,{awaitRefetchQueries:true, refetchQueries: [GET_REGISTRATIONS, 'getRegistrations']});

    const acknowledge = (id: string|null) => {
        store.AcknowledgementStore.AcknowledgeButtonDisabled = true;
        updateStatus({variables: {id: id, status: "Acknowledged", reason:null}}).then(()=>navigate("/"));
    }

    if (store?.registrationStore?.registrationData?.status !=="Approved")
    {
        return <Navigate to="/" replace={true}/>;
    }
    return (
        <>
            <object data="https://psna-public.s3.ap-southeast-2.amazonaws.com/agreement.pdf"
                    type="application/pdf" width="60%" height="700px">
                <p>Unable to display PDF file. <a
                    href="https://psna-public.s3.ap-southeast-2.amazonaws.com/agreement.pdf">Download</a> instead.
                </p>
            </object>
            <button disabled={store.AcknowledgementStore.AcknowledgeButtonDisabled} onClick={()=>acknowledge(store?.registrationStore?.registrationData?.id ?? null)}>I have read and agreed to the terms and conditions.</button>
        </>
    );
};