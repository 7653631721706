import {observer} from "mobx-react-lite";
import {useStore} from "./providers/StoreContext";
import {SIGNURL} from "./Queries";
import {useApolloClient} from "@apollo/client";
import {getUrl} from "aws-amplify/storage";

export const DownloadCertificate =  observer(()=> {
    const store = useStore();
    const client = useApolloClient();
    getUrl({
        path:({identityId})=>`private/${identityId}/${store.registrationStore.registrationData?.id}certificate.pdf`,
        options: {
            expiresIn: 3600// validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        },
    }).then((data) => {
        store.portalStore.downloadCertificateUrl = data.url.toString();
    });
    return (

        // if store.portalStore.downloadUrl is not empty, then show loading message
        // else, show the download link
        store.portalStore.downloadCertificateUrl === "" ? <div>Loading ..</div> : <a href={store.portalStore.downloadCertificateUrl}>Download your certificate</a>
    );
});